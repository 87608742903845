@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

code {
  font-family: "Inter", sans-serif;
}

.simplebar-placeholder {
  display: none;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.swiper-button-prev, .swiper-button-next{
 z-index: 99999999 !important ;
}
